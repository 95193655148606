import i18n from "@/plugins/i18n"; // plugin para internacionalização em vue.js|faz a tradução
import product_service from "@/services/api/priceList";
import { GetTodayDateTimeString } from "@/helpers/date.helper";
import { exportFileXLSX } from "@/helpers/files.helper";
import { priceListTableColumns } from "@/configs/table.columns";

const initial_state = () => ({
    loading: false,
    
    // Item list
    price: [],

    // Variables
    empresa_id: "",
    cnpj: "",
    cd_sku: "",
    referencia: "",
    last_update: "",
    fator_preco: "",
    qtd_max: "",
    qtd_min: "",
    valor_maximo: "",
    valor_minimo: "",
    valor_sugestao: "",

    
    single_filter: "",


    pagination: {
        page: 1,
        perPage: 10,
        total_items: 0,
    },

    // Single filter
    table_fields: priceListTableColumns,

    // Export variable
    modal_export_price: false,

});
const state = initial_state();

const getters = {
    loading: (state) => state.loading,

    // Item list
    price: (state) => state.price,

    // Filter
    empresa_id: (state) => state.empresa_id,
    cnpj: (state) => state.cnpj,
    qtd_max: (state) => String(state.qtd_max),
    qtd_min: (state) => String(state.qtd_min),
    cd_sku: (state) => state.cd_sku,
    referencia: (state) => String(state.referencia),
    last_update: (state) => state.last_update,
    valor_maximo: (state) => String(state.valor_maximo),
    valor_minimo: (state) => String(state.valor_minimo),
    valor_sugestao: (state) => String(state.valor_sugestao),
    fator_preco: (state) => String(state.fator_preco),

    // Single filter
    single_filter: (state) => state.single_filter,
    
    // Pagination
    total_items: (state) => state.pagination.total_items,
    current_page: (state) => state.pagination.page,
    per_page: (state) => state.pagination.perPage,
    
    // Export modal 
    modal_export_price: (state) => state.modal_export_price,
    
    // Table
    table_fields: (state, getters, rootState, rootGetters) => {
        var aux = [];
        if (rootGetters.role >= 6) {
            aux = [
                {
                    key: "empresa_id",
                    label: "pages.price_list.table_labels.company_name",
                    sortable: false,
                },
            ];
        }
        aux = aux.concat(state.table_fields);
        return aux.map((item) => ({ ...item, label: i18n.tc(item.label) }));
    },

    // APi filter
    filter_to_api: (state) => ({
        offset: state.pagination.page - 1,
        per_page: state.pagination.perPage,
        empresa_id: state.empresa_id,
        cnpj: state.cnpj,
        cd_sku: state.cd_sku,
        last_update: state.last_update,
        single_filter: state.single_filter,
    }),
};

const mutations = {
    SET_LOADING_TRUE(state) {
        state.loading = true;
    },
    SET_LOADING_FALSE(state) {
        state.loading = false;
    },

    // Filters Mutation
    SET_EMPRESA_ID: (state, id) => (state.empresa_id = id),
    SET_CD_SKU: (state, cod) => (state.cd_sku = cod),
    SET_CNPJ: (state, cnpj) => (state.cnpj = cnpj),
    SET_LAST_UPDATE: (state, last_updt) => (state.last_update = last_updt),
    SET_REFERENCIA: (state, referencia) => (state.referencia = referencia),
    SET_FATOR_PRECO: (state, fator_preco) => (state.fator_preco = fator_preco),
    SET_QTD_MAX: (state, qtd_max) => (state.qtd_max = qtd_max),
    SET_QTD_MIN: (state, qtd_min) => (state.qtd_min = qtd_min),
    SET_VALOR_MAXIMO: (state, valor_maximo) => (state.valor_maximo = valor_maximo),
    SET_VALOR_MINIMO: (state, valor_minimo) => (state.valor_minimo = valor_minimo),
    SET_VALOR_SUGESTAO: (state, valor_sugestao) => (state.valor_sugestao = valor_sugestao),

    // Single filter Mutation
    SET_SINGLE_FILTER: (state, filter) => (state.single_filter = filter),

    SET_PRICE(state, prices) {
        state.price = prices;
    },
    SET_TOTAL_ITEMS(state, total_items) {
        state.pagination.total_items = total_items;
    },
    SET_PAGE(state, page) {
        state.pagination.page = page;
    },
    SET_PER_PAGE(state, per_page) {
        state.pagination.perPage = per_page;
    },

    RESET_FILTER(state) {
        state.empresa_id = "";
        state.cnpj = "";
        state.cd_sku = "";
        state.referencia = "";
        state.last_update = "";
        state.fator_preco = "";
        state.qtd_max = "";
        state.qtd_min = "";
        state.valor_maximo = "";
        state.valor_minimo = "";
        state.valor_sugestao = "";

        state.pagination.page = 1;
    },
    RESET_DATA: (state) => Object.assign(state, initial_state()),

    // Export modal Mutation
    OPEN_MODAL_EXPORT_PRICE: (state) => (state.modal_export_price = true),
    CLOSE_MODAL_EXPORT_PRICE: (state) => (state.modal_export_price = false),

};

const actions = {
    async getPrice({ commit, getters }) {
        commit("SET_LOADING_TRUE");
        let logs_items = [];
        let total_items = 0;
        try {
            const filter = getters.filter_to_api;
            const res = await product_service.find(filter);
            const response = res.data;
            logs_items = response.slice(0, response.length - 1);
            total_items = _.find(response, "total_items")["total_items"]

        } catch {
            commit("PUSH_ERROR_TO_ARRAY_PURE_TEXT", i18n.t("action failed"), {
                root: true,
            });
        }
        commit("SET_PRICE", logs_items);
        commit("SET_TOTAL_ITEMS", total_items);
        commit("SET_LOADING_FALSE");
    },
    async openModalExportPriceList({ commit, dispatch }) {
        await dispatch("getPrice");
        commit("OPEN_MODAL_EXPORT_PRICE");
    },
    async dowloadLines({ getters, commit }) {
        try {
            const response = await product_service.export_table(getters.filter_to_api);

            const todayStringAndFilename = `${GetTodayDateTimeString({ seconds: true })}
            ${i18n.tc("pages.price_list.detailing.filename_export")}`;

            exportFileXLSX(response.data, todayStringAndFilename);

            commit("CLOSE_MODAL_EXPORT_PRICE");
        } catch (error) {
            commit("PUSH_ERROR_TO_ARRAY_PURE_TEXT", "Falha ao baixar documento", { root: true });
        }
    },

    async changePage({ commit, dispatch }, page) {
        commit("SET_PAGE", page);
        commit("saveStatus", "loading", { root: true });
        await dispatch("getPrice");
        commit("saveStatus", "success", { root: true });
    },

    async changePerPage({ commit, dispatch }, per_page) {
        commit("SET_PAGE", 1);
        commit("SET_PER_PAGE", per_page);
        commit("saveStatus", "loading", { root: true });
        await dispatch("getPrice");
        commit("saveStatus", "success", { root: true });
    },

    async search({ commit, dispatch }) {
        commit("SET_PAGE", 1);
        commit("saveStatus", "loading", { root: true });
        await dispatch("getPrice");
        commit("saveStatus", "success", { root: true });
        dispatch("closeFilterSidebar");
    },

    async clearFilter({ commit, dispatch }) {
        commit("RESET_FILTER");
        commit("saveStatus", "loading", { root: true });
        await dispatch("getPrice");
        commit("saveStatus", "success", { root: true });
        dispatch("closeFilterSidebar");
    },

    async clearDataFromStore({ commit }) {
        commit("RESET_DATA");
    },

    async closeFilterSidebar({ commit }) {
        commit("SET_RIGHT_SIDEBAR", false, { root: true });
    },

};

export default {
    namespaced: true,
    state,
    getters,
    mutations,
    actions,
};
