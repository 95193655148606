import i18n from "@/plugins/i18n";
import logs_service from "@/services/api/integration_logs";
import { Integration_Logs } from "@/configs/table.columns";

const initial_state = () => ({
    loading: false,

    data_inicio: "",
    data_final: "",
    evento: "",

    company: "",
    user_id: "",
    request_id: "",
    order_id: "",

    uuid: "",
    payload_type: "",
    status: "",
    last_update: "",

    integration_key: "",
    status_code: "",
    logs: [],

    pagination: {
        page: 1,
        perPage: 10,
        total_items: 0,
    },

    table_fields: Integration_Logs,
});

const state = initial_state();

const getters = {
    loading: (state) => state.loading,
    data_inicio: (state) => state.data_inicio,
    data_final: (state) => state.data_final,
    evento: (state) => state.evento,
    logs: (state) => state.logs,
    total_items: (state) => state.pagination.total_items,
    current_page: (state) => state.pagination.page,
    per_page: (state) => state.pagination.perPage,

    company:(state) => state.company,
    user_id:(state) => state.user_id,
    request_id:(state => state.request_id),
    order_id:(state => state.order_id),
    uuid: (state => state.uuid),
    payload_type: (state => state.payload_type),
    status: (state => state.status),
    last_update: (state => state.last_update),

    integration_key: (state => state.integration_key),
    status_code: (state => state.status_code),

    total_pages: (state) =>
        Math.ceil(state.pagination.total_items / state.pagination.perPage),
    table_fields: (state) =>
        state.table_fields.map((item) => ({
            ...item,
            label: i18n.tc(item.label),
        })),
    filter_to_api: (state) => ({
        offset: state.pagination.page - 1,
        per_page: state.pagination.perPage,
        dt_referencia_inicial: state.data_inicio,
        dt_referencia_final: state.data_final,
        evento: state.evento,

        empresa_id: state.company,
        user_id: state.user_id,
        request_id: state.request_id,
        order_id: state.order_id,

        uuid: state.uuid,
        payload_type: state.payload_type,
        status: state.status,
        last_update: state.last_update,

        integration_key: state.integration_key,
        status_code: state.status_code,
    }),
};

const mutations = {
    SET_COMPANY(state, company) {
        state.company = company;
    },
    SET_USER_ID(state, user_id) {
        state.user_id = user_id;
    },
    SET_REQUEST_ID(state, request_id) {
        state.request_id = request_id;
    },
    SET_ORDER_ID(state, order_id) {
        state.order_id = order_id;
    },
    SET_UUID(state, uuid) {
        state.uuid = uuid;
    },
    SET_PAYLOAD_TYPE(state, payload_type){
        state.payload_type = payload_type
    },
    SET_STATUS(state, status){
        state.status = status
    },
    SET_LAST_UPDATE(state, last_update){
        state.last_update = last_update
    },
    SET_INTEGRATION_KEY(state, integration_key){
        state.integration_key = integration_key
    },
    SET_STATUS_CODE(state, status_code){
        state.status_code = status_code
    },
    SET_LOADING(state, status) {
        state.loading = status;
    },
    SET_FILTER_DATE_INICIAL(state, data_inicio) {
        state.data_inicio = data_inicio;
    },
    SET_FILTER_DATE_FINAL(state, data_final) {
        state.data_final = data_final;
    },
    SET_FILTER_EVENT(state, event) {
        state.evento = event;
    },
    SET_LOGS(state, logs) {
        state.logs = logs;
    },
    SET_TOTAL_ITEMS(state, total_items) {
        state.pagination.total_items = total_items;
    },
    SET_PAGE(state, page) {
        state.pagination.page = page;
    },
    SET_PER_PAGE(state, per_page) {
        state.pagination.perPage = per_page;
    },
    RESET_FILTER(state) {
        Object.assign(state, {
            data_inicio: "",
            data_final: "",
            evento: "",
            company: "",
            user_id: "",
            request_id: "",
            order_id: "",
            uuid: "",
            payload_type: "",
            status: "",
            last_update: "",
            integration_key: "",
            status_code: "",
        });

        state.pagination.page = 1;
    },
    RESET_DATA(state) {
        Object.assign(state, initial_state());
    },
};

const actions = {
    async getLogs({ commit, getters }) {
        commit("SET_LOADING", true);

        try {

            const filter = getters.filter_to_api;
            const res = await logs_service.list(filter);

            commit("SET_LOGS", res.data.items);
            commit("SET_TOTAL_ITEMS", res.data.total_items);
        } catch (error) {
            console.error("Erro ao buscar logs:", error);
            commit(
                "PUSH_ERROR_TO_ARRAY_PURE_TEXT",
                i18n.t("action failed"),
                { root: true }
            );
        } finally {
            commit("SET_LOADING", false);
        }
    },

    async changePage({ commit, dispatch }, page) {
        commit("SET_PAGE", page);
        await dispatch("getLogs");
    },

    async changePerPage({ commit, dispatch }, per_page) {
        commit("SET_PER_PAGE", per_page);
        await dispatch("getLogs");
    },

    async search({ commit, dispatch }) {
        commit("SET_PAGE", 1);
        await dispatch("getLogs");
        dispatch("closeFilterSidebar");
    },

    async clearFilter({ commit, dispatch }) {
        commit("RESET_FILTER");
        await dispatch("getLogs");
        dispatch("closeFilterSidebar");
    },

    closeFilterSidebar({ commit }) {
        commit("SET_RIGHT_SIDEBAR", false, { root: true });
    },
};

export default {
    namespaced: true,
    state,
    getters,
    mutations,
    actions,
};
