import Vue from "vue";
import Vuex from "vuex";

// root store
import state from "./state";
import getters from "./getters";
import mutations from "./mutations";
import actions from "./actions";

// Modules
import modal from "./modules/modal";
import configs from "./modules/configs";
import admin from "./modules/admin.store";
import autocomplete from "./modules/autocomplete.store";
import upload from "./modules/upload.store";
import order from "./modules/order.store";
import checkout from "./modules/checkout.store";
import billing from "./modules/billing.store";
import help from "./modules/help.store";
import new_order from "./modules/new_order.store";
import onboarding from "./modules/onboarding.store";
import templates from "./modules/templates.store";
import centralDeComando from "./modules/centralDeComando.store";
import sugestoes from "./modules/sugestoes.store";
import files from "./modules/files.store";
import products from "./modules/products.store";
import priceList from "./modules/priceList.store";
import users from "./modules/users.store";
import managementCurator from "./modules/managementCurator.store";
import managementTemplates from "./modules/managementTemplates.store";
import dashboard from "./modules/dashboard.store";
import curator from "./modules/curator.store";
import dictionary from "./modules/dictionary.store";
import sku from "./modules/sku.store";
import logs from "./modules/logs.store";
import cockpit from "./modules/cockpit.store";
import detalhamento from "./modules/detalhamento.store";
import bonificacao from "./modules/bonificacao.store";
import pagamento from "./modules/pagamento.store";
import companies from "./modules/companies.store";
import integration_logs from "./modules/integration_logs.store";
import yml from "./modules/yml.store";

// Vuex packages to be used
Vue.use(Vuex);
const debug = process.env.NODE_ENV !== "production";

export default new Vuex.Store({
    state: state,
    getters: getters,
    actions: actions,
    mutations: mutations,
    modules: {
        configs,
        autocomplete,
        dashboard,
        modal,
        admin,
        upload,
        order,
        checkout,
        billing,
        help,
        new_order,
        onboarding,
        templates,
        centralDeComando,
        sugestoes,
        files,
        products,
        priceList,
        users,
        managementCurator,
        managementTemplates,
        curator,
        dictionary,
        sku,
        logs,
        cockpit,
        detalhamento,
        bonificacao,
        pagamento,
        companies,
        integration_logs,
        yml,
    },
    strict: debug,
});
