import yml_service from "@/services/api/yml";

const initial_state = () => ({
    loading: false,
    loading_save: false,
    loading_view: false,
    ymls: [],
    file_content: null,
    file_name: null,
    company_id: null,
    yml_filter: null,
});

const state = initial_state();

const getters = {
    loading: (state) => state.loading,
    loading_save: (state) => state.loading_save,
    loading_view: (state) => state.loading_view,
    ymls: (state) => state.ymls,
    file_content: (state) => state.file_content,
    file_name: (state) => state.file_name,
    company_id: (state) => state.company_id,
    yml_filter: (state) => state.yml_filter,
};

const mutations = {
    SET_LOADING(state, status) {
        state.loading = status;
    },
    SET_LOADING_SAVE(state, status) {
        state.loading_save = status;
    },
    SET_LOADING_VIEW(state, status) {
        state.loading_view = status;
    },
    SET_YMLS(state, ymls) {
        state.ymls = ymls;
    },
    SET_FILE_CONTENT(state, content) {
        state.file_content = content;
    },
    SET_FILE_NAME(state, name) {
        state.file_name = name;
    },
    SET_COMPANY_ID(state, id) {
        state.company_id = id;
    },
    SET_YML_FILTER(state, filter) {
        state.yml_filter = filter;
    },
};

const actions = {
    async fetchYmls({ commit }) {
        commit("SET_LOADING", true);
        try {
            const response = await yml_service.find();
            commit("SET_YMLS", response.data);
        } catch (error) {
            commit("SET_YMLS", { files: [] });
            commit("PUSH_ERROR_TO_ARRAY", "Erro ao trazer os YMLs", { root: true });
        } finally {
            commit("SET_LOADING", false);
        }
    },
    async deleteYml({ dispatch }, yml) {
        const result = await dispatch(
            "modal/ModalGeneric",
            {
                title: "Deseja excluir o arquivo?",
                text: `O arquivo ${yml.filename} será excluído permanentemente.`,
                icon: "warning",
            },
            { root: true }
        );

        if (!result) return;
        try {
            await yml_service.delete(yml);
            yml_service.load();
            dispatch("fetchYmls");
            return true;
        } catch (error) {
            console.error("Error deleting YML:", error);
            return false;
        }
    },
    async createYml({ commit, dispatch }, data) {
        commit("SET_LOADING_SAVE", true);
        try {
            const response = await yml_service.create(data);
            yml_service.load();
            commit("PUSH_SUCCESS_TO_ARRAY", response.message, { root: true });
            dispatch("fetchYmls");
        } catch (error) {
            console.error("Error creating YML:", error);
            commit("PUSH_ERROR_TO_ARRAY", error.response.data.message, { root: true });
        } finally {
            commit("SET_LOADING_SAVE", false);
        }
    },
    async updateYml({ commit, dispatch }, data) {
        commit("SET_LOADING_SAVE", true);
        try {
            const response = await yml_service.update(data);
            yml_service.load();
            commit("PUSH_SUCCESS_TO_ARRAY", response.message, { root: true });
            dispatch("fetchYmls");
        } catch (error) {
            console.error("Error updating YML:", error);
            commit("PUSH_ERROR_TO_ARRAY", error.response.data.message, { root: true });
        } finally {
            commit("SET_LOADING_SAVE", false);
        }
    },
    setYmlFilter({ commit }, filter) {
        commit("SET_YML_FILTER", filter);
    },
};

export default {
    namespaced: true,
    state,
    getters,
    mutations,
    actions,
};
