import api from "@/services/api";

export default {
    find() {
        return api().get("/views/yml");
    },
    create(data) {
        const form = new FormData();
        Object.keys(data).forEach((item) => form.append(item, data[item]));
        return api().post("/views/yml", form).then((res) => res.data);
    },
    delete(data) {
        console.log(data);
        return api().delete(`/views/yml/${data.empresa_id}`, {
            data: {
                filename: data.filename,
                type: data.type,
            },
        }).then((res) => res.data);
    },
    get(id) {
        return api().get(`/views/yml/${id}`);
    },
    update(data) {
        const form = new FormData();
        Object.keys(data).forEach((item) => form.append(item, data[item]));
        return api().put("/views/yml", form).then((res) => res.data);
    },
    load() {
        return api().post("/views/yml/load_yml");
    }
};
