import api from "@/services/api";

export default {
    list(data) {
        const filteredParams = Object.keys(data).filter((key) => data[key] !== "");
        const query = filteredParams.map((key) => `${key}=${data[key]}`).join("&");

        return api().get(`/views/integration_log?${query}`);
    },
    export(data) {
        const form = new FormData();

        form.append("empresa_id", data.company || "");
        form.append("user_id", data.user_id || "");
        form.append("request_id", data.request_id || "");
        form.append("order_id", data.order_id || "");
        form.append("uuid", data.uuid || "");
        form.append("payload", data.payload_type || "");
        form.append("status", data.status || "");
        form.append("last_update", data.last_update || "");
        form.append("integration_key", data.integration_key || "");
        form.append("status_code", data.status_code || "");

        return api().post("/views/integration_log", form);
    },
    listTransactions(data) {
        const filteredParams = Object.keys(data).filter((key) => data[key] !== "");
        const query = filteredParams.map((key) => `${key}=${data[key]}`).join("&");

        return api().get(`/views/transaction_details?${query}`);
    },
};
