import i18n from "@/plugins/i18n";
import logs_service from "@/services/api/logs";
import { logsTableColumns } from "@/configs/table.columns";
import _ from "lodash"; // Certifique-se de que lodash está instalado e importado

// Estado inicial da aplicação
const initial_state = () => ({
    loading: false,
    data_inicio: "",
    data_final: "",
    evento: "",
    logs: [],
    pagination: {
        page: 1,
        perPage: 10,
        total_items: 0,
    },
    table_fields: logsTableColumns,
});

const state = initial_state();

const getters = {
    loading: (state) => state.loading,
    data_inicio: (state) => state.data_inicio,
    data_final: (state) => state.data_final,
    evento: (state) => state.evento,
    logs: (state) => state.logs,
    total_items: (state) => state.pagination.total_items,
    current_page: (state) => state.pagination.page,
    per_page: (state) => state.pagination.perPage,
    table_fields: (state) => {
        return state.table_fields.map((item) => ({ ...item, label: i18n.tc(item.label) }));
    },
    filter_to_api: (state) => ({
        offset: state.pagination.page - 1,
        per_page: state.pagination.perPage,
        dt_referencia_inicial: state.data_inicio,
        dt_referencia_final: state.data_final,
        evento: state.evento,
    }),
};

const mutations = {
    SET_LOADING(state, status) {
        state.loading = status;
    },
    SET_FILTER_DATE_INICIAL(state, data_inicio) {
        state.data_inicio = data_inicio;
    },
    SET_FILTER_DATE_FINAL(state, data_final) {
        state.data_final = data_final;
    },
    SET_FILTER_EVENT(state, event) {
        state.evento = event;
    },
    SET_LOGS(state, logs) {
        state.logs = logs;
    },
    SET_TOTAL_ITEMS(state, total_items) {
        state.pagination.total_items = total_items;
    },
    SET_PAGE(state, page) {
        state.pagination.page = page;
    },
    SET_PER_PAGE(state, per_page) {
        state.pagination.perPage = per_page;
    },
    RESET_FILTER(state) {
        state.data_inicio = "";
        state.data_final = "";
        state.evento = "";
        state.pagination.page = 1;
    },
    RESET_DATA: (state) => {
        Object.assign(state, initial_state());
    },
};

const actions = {
    async getLogs({ commit, getters }) {
        commit("SET_LOADING", true);
        let logs_items = [];
        let total_items = 0;
        try {
            const filter = getters.filter_to_api;
            const res = await logs_service.list(filter);
            const response = res.data;

            logs_items = response.slice(0, response.length - 1);
            total_items = _.find(response, "total_items")["total_items"];
        } catch {
            commit("PUSH_ERROR_TO_ARRAY_PURE_TEXT", i18n.t("action failed"), {
                root: true,
            });
        }
        commit("SET_LOGS", logs_items);
        commit("SET_TOTAL_ITEMS", total_items);
        commit("SET_LOADING", false);
    },

    async changePage({ commit, dispatch }, page) {
        commit("SET_PAGE", page);
        commit("saveStatus", "loading", { root: true });
        await dispatch("getLogs");
        commit("saveStatus", "success", { root: true });
    },

    async changePerPage({ commit, dispatch }, per_page) {
        commit("SET_PER_PAGE", per_page);
        commit("saveStatus", "loading", { root: true });
        await dispatch("getLogs");
        commit("saveStatus", "success", { root: true });
    },

    async search({ commit, dispatch }) {
        commit("SET_PAGE", 1);
        commit("saveStatus", "loading", { root: true });
        await dispatch("getLogs");
        commit("saveStatus", "success", { root: true });
        dispatch("closeFilterSidebar");
    },

    async clearFilter({ commit, dispatch }) {
        commit("RESET_FILTER");
        commit("saveStatus", "loading", { root: true });
        await dispatch("getLogs");
        commit("saveStatus", "success", { root: true });
        dispatch("closeFilterSidebar");
    },

    closeFilterSidebar({ commit }) {
        commit("SET_RIGHT_SIDEBAR", false, { root: true });
    },
};

export default {
    namespaced: true,
    state,
    getters,
    mutations,
    actions,
};
