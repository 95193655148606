import AuthGuard from "../guards/auth-guard";
import PermissionGuard from "../guards/permission-guard";
import DashboardPage from "@/views/Dashboard/Dashboard";

export const PedmaisRoutes = [
    {
        path: "/",
        beforeEnter: AuthGuard,
        beforeUpdate: AuthGuard,
        component: () => import("@/components/board/TheBoard"),
        children: [
            {
                path: "/help_manager",
                name: "help_manager",
                component: () => import("@/views/Help/HelpList"),
            },
            {
                path: "/help_manager/:id",
                name: "help_manager_editor",
                component: () => import("@/views/Help/HelpEditor"),
            },
            {
                path: "/users_complete",
                name: "users_complete",
                component: () => import("@/views/Admin/Users"),
                meta: {
                    crumbs: [{ text: "admin" }, { text: "users" }],
                },
            },
            {
                path: "/clients",
                name: "clients",
                component: () => import("@/views/Admin/Clients"),
                meta: {
                    crumbs: [{ text: "admin" }, { text: "clients" }],
                },
            },
            {
                path: "/files",
                name: "files",
                component: () => import("@/views/Admin/Files"),
                meta: {
                    crumbs: [{ text: "admin" }, { text: "files" }],
                },
            },
            {
                path: "products",
                components: {
                    // aponta para qual componente a rota irá renderizar
                    default: () => import("@/views/Admin/Products"),
                    filter: () => import("@/components/filters/FilterProducts"),
                },
                beforeEnter: (to, from, next) => {
                    // lazy_load_store("products"); // recebe o nome do arquivo que vem da store
                    PermissionGuard(to, from, next);
                },
                name: "products",
                meta: {
                    // meta possíbilita a adição de informação a rota
                    crumbs: [{ text: "products" }, { text: "products" }],
                },
            },
            {
                path: "/price_list",
                components: {
                    // aponta para qual componente a rota irá renderizar
                    default: () => import("@/views/Admin/PriceList"),
                    filter: () => import("@/components/filters/FilterPriceList"),
                },
                beforeEnter: (to, from, next) => {
                    PermissionGuard(to, from, next);
                },
                name: "price_list",
                meta: {
                    // meta possíbilita a adição de informação a rota
                    crumbs: [{ text: "admin" }, { text: "price_list" }],
                },
            },
            {
                path: "no_permission",
                component: () => import("@/components/errors/NoPermission"),
                name: "no_permission",
            },
            {
                path: "",
                component: DashboardPage,
                name: "dashboard",
                beforeEnter: (to, from, next) => {
                    // lazy_load_store("dashboard");
                    PermissionGuard(to, from, next); // função que protege as navegações
                },
                meta: {
                    crumbs: [{ text: "dashboard", to: { name: "dashboard" } }], //'/Dashboard/Dashboard'
                },
            },
            {
                path: "/report",
                component: () => import("@/views/Dashboard/Report"),
                name: "report",
                beforeEnter: PermissionGuard,
                meta: {
                    crumbs: [{ text: "report", to: { name: "report" } }], //'/Dashboard/Dashboard'
                },
            },
            {
                path: "/onboarding",
                component: () => import("@/views/Operacao/Onboarding/Onboarding"),
                name: "onboarding",
                beforeEnter: PermissionGuard,
                meta: {
                    crumbs: [
                        { text: "operacao", to: { name: "onboarding" } },
                        { text: "onboarding" },
                    ], //'/Operacao/Dashboard'
                },
            },
            {
                path: "templates",
                component: () => import("@/views/Operacao/Templates/Templates"),
                name: "templates",
                beforeEnter: (to, from, next) => {
                    // lazy_load_store("admin");
                    PermissionGuard(to, from, next);
                },
                meta: {
                    crumbs: [{ text: "operacao" }, { text: "templates" }], //'/Operacao/Dashboard'
                },
            },
            {
                path: "container-logs",
                name: "container_logs",
                beforeEnter: (to, from, next) => {
                    // lazy_load_store("admin");
                    PermissionGuard(to, from, next);
                },
                component: () => import("@/views/Monitoring/ContainerLogs"),
                meta: {
                    crumbs: [{ text: "admin" }, { text: "container_logs" }],
                },
            },
            {
                path: "email-logs",
                name: "email_logs",
                beforeEnter: (to, from, next) => {
                    // lazy_load_store("admin");
                    PermissionGuard(to, from, next);
                },
                component: () => import("@/views/Monitoring/EmailLogs"),
                meta: {
                    crumbs: [{ text: "admin" }, { text: "email_logs" }],
                },
            },
            {
                path: "companies",
                name: "companies",
                beforeEnter: (to, from, next) => {
                    // lazy_load_store("admin");
                    PermissionGuard(to, from, next);
                },
                component: () => import("@/views/Admin/CompaniesLogo"),
                meta: {
                    crumbs: [{ text: "admin" }, { text: "companies" }],
                },
            },
            {
                path: "admin-tasks",
                name: "admin_tasks",
                beforeEnter: (to, from, next) => {
                    // lazy_load_store("admin");
                    PermissionGuard(to, from, next);
                },
                component: () => import("@/views/Admin/Tasks"),
                meta: {
                    crumbs: [{ text: "admin" }, { text: "tasks" }],
                },
            },
            {
                path: "admin-suggestions",
                name: "admin_suggestions",
                component: () => import("@/views/Admin/Suggestions"),
                meta: {
                    crumbs: [{ text: "admin" }, { text: "suggestions" }],
                },
            },
            {
                path: "profile",
                component: () => import("@/views/Users/Profile"),
                name: "profile",
                beforeEnter: PermissionGuard,
                meta: {
                    crumbs: [{ text: "profile", to: { name: "profile" } }],
                },
            },
            {
                path: "curator",
                components: {
                    default: () => import("@/views/Curatorship/Curator"),
                    filter: () => import("@/components/filters/FilterCurator"),
                },
                name: "curator",
                beforeEnter: (to, from, next) => {
                    // lazy_load_store("curator");
                    PermissionGuard(to, from, next);
                },
                props: true,
                meta: {
                    crumbs: [{ text: "curator" }, { text: "validation", to: { name: "curator" } }],
                },
            },
            {
                path: "dictionary",
                beforeEnter: (to, from, next) => {
                    // lazy_load_store("dictionary");
                    PermissionGuard(to, from, next);
                },
                components: {
                    default: () => import("@/views/Products/Dictionary"),
                    filter: () => import("@/components/filters/FilterDictionary"),
                },
                name: "dictionary",
                meta: {
                    crumbs: [
                        { text: "curator" },
                        { text: "dictionary", to: { name: "dictionary" } },
                    ],
                },
            },
            {
                path: "sku",
                components: {
                    default: () => import("@/views/Products/SKU"),
                    filter: () => import("@/components/filters/FilterSKU"),
                },
                beforeEnter: (to, from, next) => {
                    // lazy_load_store("sku");
                    PermissionGuard(to, from, next);
                },
                name: "sku",
                meta: {
                    crumbs: [{ text: "curator" }, { text: "sku", to: { name: "sku" } }],
                },
            },
            {
                path: "logs",
                components: {
                    default: () => import("@/views/Monitoring/Logs"),
                    filter: () => import("@/components/filters/FilterLogs"),
                },
                beforeEnter: (to, from, next) => {
                    // lazy_load_store("logs");
                    PermissionGuard(to, from, next);
                },
                name: "logs",
                meta: {
                    crumbs: [{ text: "history" }, { text: "logs", to: { name: "logs" } }], //'/Histórico/Logs'
                },
            },
            {
                path: "users",
                beforeEnter: (to, from, next) => {
                    PermissionGuard(to, from, next);
                },
                components: {
                    default: () => import("@/views/Users/UsersList"),
                    filter: () => import("@/components/filters/FilterUsers"),
                },
                name: "users",
                meta: {
                    crumbs: [{ text: "users" }, { text: "list_users", to: { name: "users" } }], //'Usuários/Lista de usuários'
                },
            },
            {
                path: "cockpit",
                components: {
                    default: () => import("@/views/Monitoring/Cockpit"),
                    filter: () => import("@/components/filters/FilterCockpit"),
                },
                beforeEnter: (to, from, next) => {
                    // lazy_load_store("cockpit");
                    PermissionGuard(to, from, next);
                },
                name: "cockpit",
                meta: {
                    crumbs: [{ text: "monitoring" }, { text: "cockpit", to: { name: "cockpit" } }], //'/Monitoramento/cockpit'
                },
            },
            {
                path: "integration_logs",
                components: {
                    default: () => import("@/views/Monitoring/integration_logs"),
                },
                beforeEnter: (to, from, next) => {
                    PermissionGuard(to, from, next);
                },
                name: "integration_logs",
                meta: {
                    crumbs: [{ text: "integration_logs", to: { name: "integration_logs" } }], //'/integração/Logs'
                },
            },
            {
                path: "detalhamento",
                components: {
                    default: () => import("@/views/Monitoring/Detalhamento"),
                    filter: () => import("@/components/filters/FilterDetailing"),
                },
                beforeEnter: (to, from, next) => {
                    // lazy_load_store("detalhamento");
                    PermissionGuard(to, from, next);
                },
                name: "detalhamento",
                props: true,
                meta: {
                    crumbs: [
                        { text: "monitoring" },
                        { text: "detailing", to: { name: "detalhamento" } },
                    ], //'/Monitoramento/Detalhamento'
                },
            },
            {
                path: "curatorship",
                components: {
                    default: () => import("@/views/Curatorship/Curatorship"),
                    filter: () => import("@/components/filters/FilterCuratorship"),
                },
                beforeEnter: (to, from, next) => {
                    // lazy_load_store("upload");
                    PermissionGuard(to, from, next);
                },
                name: "upload",
                meta: {
                    crumbs: [{ text: "orders" }, { text: "upload", to: { name: "name" } }], //'/orders/Upload'
                },
            },
            {
                path: "managementCurator",
                components: {
                    default: () => import("@/views/Curatorship/ManagementCurator"),
                    filter: () => import("@/views/Curatorship/components/FilterManagementCurator"),
                },
                name: "managementCurator",
                meta: {
                    crumbs: [
                        { text: "orders" },
                        { text: "managementCurator", to: { name: "managementCurator" } },
                    ],
                },
            },
            {
                path: "approval",
                components: {
                    default: () => import("@/views/Curatorship/Approval/Approval"),
                },
                name: "approval",

                meta: {
                    crumbs: [{ text: "orders", to: { name: "upload" } }, { text: "approval" }], //'/orders/Upload'
                },
            },
            {
                path: "new_order/:id",
                components: {
                    default: () => import("@/views/Curatorship/NewOrder/NewOrder"),
                    // filter: () => import("@/components/filters/FilterCuratorship"),
                },
                // beforeEnter: (to, from, next) => {
                //     lazy_load_store("upload");
                //     PermissionGuard(to, from, next);
                // },
                name: "new_order",
                meta: {
                    crumbs: [{ text: "orders" }, { text: "new_order" }], //'/orders/Upload'
                },
            },
            {
                path: "order",
                components: {
                    default: () => import("@/views/Curatorship/Order"),
                    filter: () => import("@/components/filters/FilterOrder"),
                },
                beforeEnter: (to, from, next) => {
                    // lazy_load_store("order");
                    PermissionGuard(to, from, next);
                },
                name: "order",
                props: true,
                meta: {
                    crumbs: [
                        { text: "orders", to: { name: "upload" } },
                        { text: "order", to: { name: "order" } },
                    ],
                },
            },
            {
                path: "checkout",
                components: {
                    default: () => import("@/views/Curatorship/Checkout"),
                    filter: () => import("@/views/Curatorship/components/FilterCheckout"),
                },
                beforeEnter: (to, from, next) => {
                    // lazy_load_store("checkout");
                    PermissionGuard(to, from, next);
                },
                name: "checkout",
                props: true,
                meta: {
                    crumbs: [
                        { text: "orders", to: { name: "upload" } },
                        { text: "order", to: { name: "order" } },
                        { text: "checkout", to: { name: "checkout" } },
                    ],
                },
            },
            {
                path: "bonificacao",
                components: {
                    default: () => import("@/views/Bonus/Bonificacao"),
                    filter: import("@/components/filters/FilterBonus"),
                },
                name: "bonificacao",
                beforeEnter: (to, from, next) => {
                    // lazy_load_store("bonificacao");
                    PermissionGuard(to, from, next);
                },
                props: true,
                meta: {
                    crumbs: [{ text: "orders" }, { text: "bonus", to: { name: "bonificacao" } }], //'/Pedidos/Bonificação'
                },
            },
            {
                path: "erro-400",
                component: () => import("@/components/errors/The400"),
                name: "400",
            },
            {
                path: "sku_manager/:id",
                components: {
                    default: () => import("@/views/Products/NovoSKU"),
                },
                beforeEnter: (to, from, next) => {
                    // lazy_load_store("sku");
                    PermissionGuard(to, from, next);
                },
                name: "new_edit_sku",
                meta: {
                    crumbs: [{ text: "admin" }, { text: "new_sku" }],
                },
            },
            {
                path: "pagamento_cockpit",
                components: {
                    default: () => import("@/views/Payments/Monitoramento"),
                    // filter: () => import("@/components/filters/FilterMonitoramentoPagamento"),
                },
                beforeEnter: (to, from, next) => {
                    // lazy_load_store("pagamento");
                    PermissionGuard(to, from, next);
                },
                name: "pagamento_cockpit",
                meta: {
                    crumbs: [{ text: "pagamento" }, { text: "pagamento_cockpit" }],
                },
            },
            {
                path: "pagamento_detalhamento",
                components: {
                    default: () => import("@/views/Payments/Detalhamento"),
                    // filter: () => import("@/components/filters/FilterDetalhamentoPagamento"),
                },
                beforeEnter: (to, from, next) => {
                    // lazy_load_store("pagamento");
                    PermissionGuard(to, from, next);
                },
                name: "pagamento_detalhamento",
                meta: {
                    crumbs: [{ text: "pagamento" }, { text: "pagamento_detalhamento" }],
                },
            },
            {
                path: "pagamento_upload",
                component: () => import("@/views/Payments/Upload"),
                beforeEnter: (to, from, next) => {
                    // lazy_load_store("pagamento");
                    PermissionGuard(to, from, next);
                },
                name: "pagamento_upload",
                meta: {
                    crumbs: [{ text: "pagamento" }, { text: "pagamento_upload" }],
                },
            },
            {
                path: "gerenciador_templates",
                components: {
                    default: () => import("@/views/Admin/Templates"),
                    filter: () => import("@/components/filters/FilterManagementTemplates"),
                },
                name: "gerenciador_templates",
                beforeEnter: (to, from, next) => {
                    // lazy_load_store("managementTemplates");
                    PermissionGuard(to, from, next);
                },
                props: true,
                meta: {
                    crumbs: [{ text: "templates", to: { name: "templates" } }],
                },
            },
            {
                path: "yml",
                components: {
                    default: () => import("@/views/Operacao/Yml/Yml"),
                    filter: () => import("@/components/filters/FilterYml"),
                },
                name: "yml",
                beforeEnter: (to, from, next) => {
                    PermissionGuard(to, from, next);
                },
                props: true,
                meta: {
                    crumbs: [{ text: "yml", to: { name: "yml" } }],
                },
            },
        ],
    },
];
