const createBlobAndDownload = function (value, filename, type = null) {
    let params = !type ? {} : { type };
    let blob = new Blob([value], params);

    var link = document.createElement("a");
    link.href = window.URL.createObjectURL(blob);
    link.download = filename;
    link.click();
};

export const exportFileCSV = (value, filename) =>
    createBlobAndDownload(value, `${filename}.csv`, "application/csv");
export const exportFileTXT = (value, filename) =>
    createBlobAndDownload(value, `${filename}.txt`, "text/txt");
export const exportFilePDF = (value, filename) =>
    createBlobAndDownload(value, filename, "application/pdf");
export const exportFileXLSX = (value, filename) =>
    createBlobAndDownload(value, `${filename}.xlsx`);
export const exportFileXLS = (value, filename) =>
    createBlobAndDownload(value, `${filename}.xls`);
