import api from "@/services/api";

export default {
    find(data) {
        const payload = {
            ...data, // Insere todas as chaves e valores de `data` no objeto
        };
        return api().post("/views/price_list", payload);
    },
    export_table(data) {
        var filtered_obj = Object.keys(data).filter((item) => data[item] !== "");
        var query = filtered_obj.map((item) => `${item}=${data[item]}`);
        return api().post("/views/price_list/export_file?" + query.join("&"), null, {
            responseType: "blob",
            headers: {
                Accept: "application/json, text/plain, */*",
                "Content-Type": "*",
            },
        });
    },
};
