import {
    localStorageGet,
    sessionStorageGet,
    retrieveFromLocalStorageAndConvert,
} from "@/helpers/storage.helper";

const is_authenticated = localStorageGet("isAuthenticated_pedmais");

const state = {
    appName: "ped+",
    appVersion: "1.0",
    denied_url: "",
    window_width: 1400,

    // Auth
    loading_login: false,
    status: "",
    status_processing: false,
    token: localStorageGet("access_token"),
    isAuthenticated: is_authenticated ? is_authenticated : false,
    username: localStorageGet("username"),
    name: localStorageGet("name"),
    company: localStorageGet("company"),
    role: localStorageGet("role"),
    client_email: localStorageGet("client_email"),
    location: localStorageGet("location"),
    foto_url: localStorageGet("foto_url"),
    lista_empresas: localStorageGet("lista_empresas"),
    logged_from_admin_account: sessionStorageGet("logged_from_admin_account"),
    type_loggin: localStorageGet("type_loggin"),
    pages_limited: localStorageGet("pages") || [],
    new_features: localStorageGet("new_features"),
    owner_id: localStorageGet("owner_id"),
    company_id: localStorageGet("company_id"),
    login_saved: "",
    show_optin_whatsapp: false,

    global_fields_parameters: null,

    modules: retrieveFromLocalStorageAndConvert("modules_pedmais"),

    advanced_order: false,
    edit_order_number: false,

    custom_filters: [],

    splash_screen: true,

    // Crumbs
    crumbArray: [
        {
            text: "",
            to: "",
        },
    ],

    // Sidebar
    active: undefined,
    collapsed: localStorageGet("collpased_sidebar") || false,
    hidden: true,

    right_open: false,

    // notifications
    notifications: [],

    //
};

export default state;
