import { returnClassLinhaDeletadaIfItemIsDeletedAndIsNotRow } from "@/helpers/classes.helper";

export const cardCheckoutTableColumns = [
    {
        key: "selection",
    },
    {
        key: "cd_sku_output",
        label: "pages.checkout.table_labels.sku",
        thClass: "",
        tdClass: (value, key, item) =>
            returnClassLinhaDeletadaIfItemIsDeletedAndIsNotRow(key, item),
        sortable: true,
    },
    {
        key: "cd_ean_output",
        label: "pages.checkout.table_labels.ean",
        thClass: "",
        tdClass: (value, key, item) =>
            returnClassLinhaDeletadaIfItemIsDeletedAndIsNotRow(key, item),
        sortable: true,
    },
    {
        key: "ds_sku_output",
        label: "pages.checkout.table_labels.description",
        thClass: "",
        tdClass: (value, key, item) =>
            returnClassLinhaDeletadaIfItemIsDeletedAndIsNotRow(key, item),
        sortable: true,
    },
    {
        key: "und",
        label: "pages.checkout.table_labels.unity",
        tdClass: (value, key, item) =>
            returnClassLinhaDeletadaIfItemIsDeletedAndIsNotRow(key, item),
        sortable: true,
    },
    {
        key: "vl_p_unitario",
        label: "pages.checkout.table_labels.price",
        tdClass: (value, key, item) =>
            `text-center ${returnClassLinhaDeletadaIfItemIsDeletedAndIsNotRow(key, item)}`,
        sortable: false,
        formatter: (value) => {
            return value.toLocaleString("pt-BR", { minimumFractionDigits: 2 });
        },
    },
    {
        key: "qty",
        label: "pages.checkout.table_labels.qtd",
        tdClass: (value, key, item) =>
            `text-center ${returnClassLinhaDeletadaIfItemIsDeletedAndIsNotRow(key, item)}`,
        sortable: false,
    },
    {
        key: "qty_arquivo",
        label: "pages.checkout.table_labels.qtd_file",
        tdClass: (value, key, item) =>
            returnClassLinhaDeletadaIfItemIsDeletedAndIsNotRow(key, item),
    },
    {
        key: "total",
        label: "pages.checkout.table_labels.total_value",
        tdClass: (value, key, item) =>
            `text-center ${returnClassLinhaDeletadaIfItemIsDeletedAndIsNotRow(key, item)}`,
        sortable: false,
        formatter: (value) => {
            return value.toLocaleString("pt-BR", { minimumFractionDigits: 2 });
        },
    },
    {
        key: "dt_entrega",
        label: "pages.checkout.table_labels.delivery_date",
        thClass: "text-center ",
        tdClass: (value, key, item) =>
            `text-center ${returnClassLinhaDeletadaIfItemIsDeletedAndIsNotRow(key, item)}`,
        sortable: true,
    },
    {
        key: "item_log",
        label: "pages.checkout.table_labels.obs",
        thClass: "",
        tdClass: (value, key, item) =>
            returnClassLinhaDeletadaIfItemIsDeletedAndIsNotRow(key, item),
        sortable: true,
    },
    {
        key: "acoes",
        label: "pages.checkout.table_labels.actions",
    },
    {
        key: "status_checkout",
        label: "",
    },
];

export const orderTableColumns = [
    {
        key: "selection",
    },
    {
        key: "ds_sku_input",
        label: "pages.order.table_labels.from",
        sortable: true,
    },
    {
        key: "new_suggestion",
        label: "pages.order.table_labels.new_suggestion",
        tdClass: "new-suggestion-min-width",
        sortable: false,
    },
    {
        key: "vl_p_unitario",
        label: "pages.order.table_labels.price",
        thClass: "text-center",
        tdClass: "text-center",
        sortable: false,
        formatter: (value) => {
            return value.toLocaleString("pt-BR", { minimumFractionDigits: 2 });
        },
    },
    {
        key: "qtd",
        label: "pages.order.table_labels.qtd",
        thClass: "text-center",
        tdClass: "text-center",
        sortable: false,
    },
    {
        key: "item_log",
        label: "pages.order.table_labels.obs",
        sortable: true,
    },
    {
        key: "total",
        label: "pages.order.table_labels.total_value",
        thClass: "text-center",
        tdClass: "text-center",
        sortable: false,
        formatter: (value) => {
            return value.toLocaleString("pt-BR", { minimumFractionDigits: 2 });
        },
    },
    {
        key: "tipo_pedido",
        label: "pages.order.table_labels.type_order",
        tdClass: "text-center",
        thClass: "text-center",
        sortable: true,
    },
    {
        key: "status_pedmais",
        label: "pages.order.table_labels.curator",
        tdClass: "text-center",
        thClass: "text-center",
        sortable: true,
    },
    {
        key: "acoes",
        label: "",
    },
];

export const curatorshipTableColumns = [
    {
        key: "selection",
        label: "",
    },
    {
        key: "dt_ref",
        label: "pages.upload.table_labels.date",
        sortable: true,
        formatter: (value) => {
            if (!value) return value;
            return new Date(value).toLocaleString("pt-BR", { timeZone: "GMT" });
        },
    },
    {
        key: "nome_cliente",
        label: "pages.upload.table_labels.client",
        sortable: true,
    },
    {
        key: "country",
        label: "pages.upload.table_labels.country",
        sortable: true,
    },
    {
        key: "nome_usuario",
        label: "pages.upload.table_labels.user",
        sortable: true,
    },
    {
        key: "canal",
        label: "pages.upload.table_labels.channel",
        sortable: true,
    },
    {
        key: "filepath",
        label: "pages.upload.table_labels.document",
        tdClass: "document-name",
        thClass: "document-name",
        sortable: true,
    },
    {
        key: "total",
        label: "pages.upload.table_labels.total",
        thClass: "text-sm-center",
        tdClass: "text-sm-center",
        sortable: false,
        formatter: (value) => {
            return value.toLocaleString("pt-BR", { minimumFractionDigits: 2 });
        },
    },
    {
        key: "total_compra",
        label: "pages.upload.table_labels.total_sales",
        thClass: "text-sm-center",
        tdClass: "text-sm-center",
        sortable: false,
        formatter: (value) => {
            return value.toLocaleString("pt-BR", { minimumFractionDigits: 2 });
        },
    },
    {
        key: "total_bonificacao",
        label: "pages.upload.table_labels.total_bonus",
        thClass: "text-sm-center",
        tdClass: "text-sm-center",
        sortable: false,
        formatter: (value) => {
            return value.toLocaleString("pt-BR", { minimumFractionDigits: 2 });
        },
    },
    {
        key: "porcentagem",
        label: "pages.upload.table_labels.percentage",
    },
    {
        key: "status_integracao",
        label: "pages.upload.table_labels.log",
        thClass: "coluna-log-integracao",
        tdClass: "coluna-log-integracao",
        sortable: true,
    },
    {
        key: "status",
        label: "pages.upload.table_labels.status",
        tdClass: "text-sm-center",
        thClass: "text-sm-center",
        sortable: true,
    },
    {
        key: "acoes",
        label: "pages.upload.table_labels.actions",
    },
];

export const detalhamentoTableColumns = [
    {
        key: "dt_ref",
        label: "pages.detailing.table_labels.dt_ref",
        sortable: false,
        formatter: (value) => {
            if (!value) return value;
            return new Date(value).toLocaleString("pt-BR", { timeZone: "GMT" });
        },
    },
    {
        key: "nome_cliente",
        label: "pages.detailing.table_labels.client",
        sortable: false,
    },
    {
        key: "canal",
        label: "pages.detailing.table_labels.channel",
        sortable: false,
    },
    {
        key: "nome_usuario",
        label: "pages.detailing.table_labels.user",
        sortable: false,
    },
    {
        key: "cnpj",
        label: "pages.detailing.table_labels.cnpj",
        thClass: "text-center",
        tdClass: "text-center",
        sortable: false,
    },
    {
        key: "num_pedido",
        label: "pages.detailing.table_labels.num_order",
        thClass: "text-center",
        tdClass: "text-center",
        sortable: false,
    },
    {
        key: "tipo_pedido",
        label: "pages.detailing.table_labels.type_order",
    },
    {
        key: "sku_input",
        label: "pages.detailing.table_labels.cd_sku_input",
        thClass: "text-center",
    },
    {
        key: "sku_output",
        label: "pages.detailing.table_labels.cd_sku_output",
        thClass: "text-center",
    },
    {
        key: "qty",
        label: "pages.detailing.table_labels.qtd",
        thClass: "text-center",
        tdClass: "text-center",
        sortable: false,
    },
    {
        key: "vl_p_unitario",
        label: "pages.detailing.table_labels.price",
        thClass: "text-center",
        tdClass: "text-center",
        sortable: false,
        formatter: (value) => {
            if (!value) return value;
            return value.toLocaleString("pt-BR", { minimumFractionDigits: 2 });
        },
    },
    {
        key: "qty_arquivo",
        label: "pages.detailing.table_labels.qtd_file",
        thClass: "text-center",
        tdClass: "text-center",
        sortable: false,
    },
    {
        key: "valor",
        label: "pages.detailing.table_labels.value",
        thClass: "text-center",
        tdClass: "text-center",
        sortable: false,
        formatter: (value) => {
            if (!value) return value;
            return value.toLocaleString("pt-BR", { minimumFractionDigits: 2 });
        },
    },
    {
        key: "dt_entrega",
        label: "pages.detailing.table_labels.delivery_date",
        tdClass: "text-center",
        thClass: "text-center",
        sortable: false,
    },
    {
        key: "status_pedmais",
        label: "pages.detailing.table_labels.status_pedmais",
        tdClass: "text-center",
        thClass: "text-center",
        sortable: false,
    },
    {
        key: "status_erp",
        label: "pages.detailing.table_labels.status_erp",
        tdClass: "text-center",
        thClass: "text-center",
        sortable: false,
    },
    {
        key: "timestamp_erp",
        label: "pages.detailing.table_labels.timestamp",
        thClass: "text-center",
        tdClass: "text-center",
        sortable: false,
    },
    {
        key: "log",
        label: "pages.detailing.table_labels.log",
        thClass: "coluna-max-width",
        tdClass: "coluna-max-width",
        sortable: false,
    },
];

export const cockpitTableColumns = [
    {
        key: "dt_ref",
        label: "pages.cockpit.table_labels.dt_ref",
        sortable: false,
        formatter: (value) => {
            return new Date(value).toLocaleString("pt-BR", { timeZone: "GMT" });
        },
    },
    {
        key: "nome_empresa",
        label: "pages.cockpit.table_labels.company",
        sortable: false,
        roles: [7, 6],
    },
    {
        key: "nome_cliente",
        label: "pages.cockpit.table_labels.client",
        sortable: false,
    },
    {
        key: "canal",
        label: "pages.cockpit.table_labels.channel",
        sortable: false,
    },
    {
        key: "nome_usuario",
        label: "pages.cockpit.table_labels.user",
        sortable: false,
    },
    {
        key: "qtd_lojas",
        label: "pages.cockpit.table_labels.stores",
        thClass: "text-center",
        tdClass: "text-center",
        sortable: false,
    },
    {
        key: "qtd_total_pedidos",
        label: "pages.cockpit.table_labels.orders",
        thClass: "text-center",
        tdClass: "text-center",
        sortable: false,
    },
    {
        key: "qtd_pedidos_curadoria",
        label: "pages.cockpit.table_labels.curator_orders",
        thClass: "text-center",
        tdClass: "text-center",
        sortable: false,
    },
    {
        key: "qtd_total_itens",
        label: "pages.cockpit.table_labels.items",
        thClass: "text-center",
        tdClass: "text-center",
        sortable: false,
    },
    {
        key: "qtd_pendentes_curadoria",
        label: "pages.cockpit.table_labels.curator_items",
        thClass: "text-center",
        tdClass: "text-center",
        sortable: false,
    },
    {
        key: "valor_total_pedidos",
        label: "pages.cockpit.table_labels.total_orders",
        thClass: "text-center",
        tdClass: "text-center",
        sortable: false,
        formatter: (value) => {
            return value.toLocaleString("pt-BR", { minimumFractionDigits: 2 });
        },
    },
    {
        key: "valor_pedido_curadoria",
        label: "pages.cockpit.table_labels.curator_orders_value",
        thClass: "text-center",
        tdClass: "text-center",
        sortable: false,
        formatter: (value) => {
            return value.toLocaleString("pt-BR", { minimumFractionDigits: 2 });
        },
    },
    {
        key: "status_pedmais",
        label: "pages.cockpit.table_labels.status_pedmais",
        tdClass: "text-center",
        thClass: "text-center",
        sortable: false,
    },
    {
        key: "status_erp",
        label: "pages.cockpit.table_labels.status_erp",
        tdClass: "text-center",
        thClass: "text-center",
        sortable: false,
    },
    {
        key: "log",
        label: "pages.cockpit.table_labels.log",
        thClass: "coluna-max-width",
        tdClass: "coluna-max-width",
        sortable: false,
    },
    {
        key: "file",
        label: "pages.cockpit.table_labels.document",
        thClass: "coluna-max-width",
        tdClass: "coluna-max-width",
        sortable: false,
    },
];

export const onboardingTableColumns = [
    {
        key: "uploaded",
        label: "pages.onboarding.table_labels.dt_ref",
        sortable: false,
        formatter: (value) => {
            if (!value) return value;
            return new Date(value).toLocaleString("pt-BR", { timeZone: "GMT" });
        },
    },
    {
        key: "company",
        label: "pages.onboarding.table_labels.company",
        sortable: false,
        roles: [7, 6],
    },
    {
        key: "country",
        label: "pages.onboarding.table_labels.country",
        sortable: false,
    },
    {
        key: "user",
        label: "pages.onboarding.table_labels.user",
        sortable: false,
    },
    {
        key: "info",
        label: "pages.onboarding.table_labels.info",
        sortable: false,
    },
    {
        key: "filename",
        label: "pages.onboarding.table_labels.filename",
        sortable: false,
    },
    {
        key: "multpoc",
        label: "pages.onboarding.table_labels.multpoc",
        sortable: false,
    },
    {
        key: "status_description",
        label: "pages.onboarding.table_labels.status_integration",
        sortable: false,
        thClass: "text-center",
        tdClass: "text-center",
    },
    {
        key: "owner",
        label: "pages.onboarding.table_labels.owner",
        sortable: false,
        thClass: "text-center",
        tdClass: "text-center",
        roles: [7, 6],
    },
    {
        key: "status",
        label: "pages.onboarding.table_labels.status",
        sortable: false,
        thClass: "text-center",
        tdClass: "text-center",
    },
    {
        key: "details",
        label: "pages.onboarding.table_labels.acoes",
        sortable: false,
        thClass: "text-center",
        tdClass: "text-center",
    },
];

export const templatesTableColumns = [
    {
        key: "last_update",
        label: "pages.templates.table_labels.last_update",
        sortable: false,
        formatter: (value) => {
            if (!value) return value;
            return new Date(value).toLocaleString("pt-BR", { timeZone: "GMT" });
        },
    },
    {
        key: "empresa_id",
        label: "pages.templates.table_labels.empresa_id",
        sortable: false,
        thClass: "coluna-max-width",
        tdClass: "coluna-max-width",
    },
    {
        key: "cnpj",
        label: "pages.templates.table_labels.cnpj",
        sortable: false,
    },
    {
        key: "sigla",
        label: "pages.templates.table_labels.sigla",
        sortable: false,
    },
    {
        key: "thumbnail",
        label: "pages.templates.table_labels.thumbnail",
        sortable: false,
    },
    {
        key: "template",
        label: "pages.templates.table_labels.template",
        sortable: false,
    },
    {
        key: "status",
        label: "pages.templates.table_labels.status",
        sortable: false,
        thClass: "text-center",
        tdClass: "text-center",
    },
    {
        key: "owner",
        label: "pages.templates.table_labels.owner",
        sortable: false,
        thClass: "text-center",
        tdClass: "text-center",
    },
    {
        key: "actions",
        label: "pages.templates.table_labels.actions",
        sortable: false,
        thClass: "text-center",
        tdClass: "text-center",
    },
];

export const usersTableColumns = [
    { key: "id", label: "pages.users.table_labels.id", sortable: false },
    { key: "username", label: "pages.users.table_labels.user", sortable: false },
    { key: "name", label: "pages.users.table_labels.name", sortable: false },
    { key: "email", label: "pages.users.table_labels.email", sortable: false },
    { key: "client_email", label: "pages.users.table_labels.client_email", sortable: false },
    { key: "role_name", label: "pages.users.table_labels.role", sortable: false },
    { key: "actions", label: "pages.users.table_labels.actions", tdClass: "coluna-acoes" },
];

export const skuTableColumns = [
    { key: "fator", label: "pages.sku.table_labels.factor", sortable: false },
    { key: "peso_liquido", label: "pages.sku.table_labels.net_weight", sortable: false },
    { key: "categoria", label: "pages.sku.table_labels.category", sortable: false },
    { key: "marca", label: "pages.sku.table_labels.brand", sortable: false },
    { key: "cd_sku", label: "pages.sku.table_labels.cd_sku", sortable: false },
    { key: "ds_sku", label: "pages.sku.table_labels.ds_sku", sortable: false },
    { key: "cd_ean", label: "pages.sku.table_labels.cd_ean", sortable: false },
    { key: "embalagem", label: "pages.sku.table_labels.package", sortable: false },
    { key: "status", label: "pages.sku.table_labels.status", sortable: false },
];

export const productsTableColumns = [
    { key: "categoria", label: "pages.products.table_labels.category", sortable: false },
    { key: "cd_sku", label: "pages.products.table_labels.cd_sku", sortable: false },
    { key: "ds_sku", label: "pages.products.table_labels.ds_sku", sortable: false },
    { key: "cd_ean", label: "pages.products.table_labels.cd_ean", sortable: false },
    { key: "status", label: "pages.products.table_labels.status", sortable: false },
];

export const priceListTableColumns = [
    { key: "cnpj", label: "pages.price_list.table_labels.cnpj", sortable: false },
    { key: "cd_sku", label: "pages.price_list.table_labels.cd_sku", sortable: false },
    { key: "referencia", label: "pages.price_list.table_labels.reference", sortable: false },
    { key: "fator_preco", label: "pages.price_list.table_labels.fator_price", sortable: false },
    { key: "valor_sugestao", label: "pages.price_list.table_labels.valor_sugestao", sortable: false },
    { key: "valor_minimo", label: "pages.price_list.table_labels.valor_minimo", sortable: false },
    { key: "valor_maximo", label: "pages.price_list.table_labels.valor_maximo", sortable: false },
    { key: "qtd_min", label: "pages.price_list.table_labels.qtd_min", sortable: false },
    { key: "qtd_max", label: "pages.price_list.table_labels.qtd_max", sortable: false },
    { key: "last_update", label: "pages.price_list.table_labels.last_update", sortable: false },
];

export const paymentTableColumns = [
    {
        key: "invoice",
        label: "pages.payments.detailing.table_labels.invoice",
        thClass: "text-center",
        tdClass: "text-center",
    },
    {
        key: "date",
        label: "pages.payments.detailing.table_labels.dt_ref",
        thClass: "text-center",
        tdClass: "text-center",
    },
    {
        key: "call_date",
        label: "pages.payments.detailing.table_labels.call_date",
        thClass: "text-center",
        tdClass: "text-center",
    },
    {
        key: "bill_of_lading",
        label: "pages.payments.detailing.table_labels.bl",
        thClass: "text-center",
        tdClass: "text-center",
    },
    {
        key: "your_ref",
        label: "pages.payments.detailing.table_labels.your_ref",
        thClass: "text-center",
        tdClass: "text-center",
    },
    {
        key: "vessel",
        label: "pages.payments.detailing.table_labels.vessel",
        thClass: "text-center",
        tdClass: "text-center",
    },
    {
        key: "discharge_port",
        label: "pages.payments.detailing.table_labels.discharge_port",
        thClass: "text-center",
        tdClass: "text-center",
    },
    {
        key: "consignee",
        label: "pages.payments.detailing.table_labels.consignee",
        thClass: "text-center",
        tdClass: "text-center",
    },
    {
        key: "qty",
        label: "pages.payments.detailing.table_labels.qty",
        thClass: "text-center",
        tdClass: "text-center",
    },
    {
        key: "size_type",
        label: "pages.payments.detailing.table_labels.size_type",
        thClass: "text-center",
        tdClass: "text-center",
    },
    {
        key: "charge_description",
        label: "pages.payments.detailing.table_labels.charge_description",
        thClass: "text-center",
        tdClass: "text-center",
    },
    {
        key: "based_on",
        label: "pages.payments.detailing.table_labels.based_on",
        thClass: "text-center",
        tdClass: "text-center",
    },
    {
        key: "rate",
        label: "pages.payments.detailing.table_labels.rate",
        thClass: "text-center",
        tdClass: "text-center",
    },
    {
        key: "currency",
        label: "pages.payments.detailing.table_labels.currency",
    },
    {
        key: "amount",
        label: "pages.payments.detailing.table_labels.amount",
        thClass: "text-center",
        tdClass: "text-center",
    },
    {
        key: "total_amount",
        label: "pages.payments.detailing.table_labels.total_amount",
        thClass: "text-center",
        tdClass: "text-center",
    },
];

export const paymentMonitoringTableColumns = [
    {
        key: "selection",
    },
    {
        key: "id",
        label: "pages.payments.export.table_labels.id",
        thClass: "text-center",
        tdClass: "text-center",
    },
    {
        key: "dt_upload",
        label: "pages.payments.export.table_labels.dt_upload",
        thClass: "text-center",
        tdClass: "text-center",
    },
    {
        key: "dt_processing",
        label: "pages.payments.export.table_labels.dt_processing",
        thClass: "text-center",
        tdClass: "text-center",
    },
    {
        key: "user",
        label: "pages.payments.export.table_labels.user",
        thClass: "text-center",
        tdClass: "text-center",
    },
    {
        key: "id_invoice",
        label: "pages.payments.export.table_labels.id_invoice",
        thClass: "text-center",
        tdClass: "",
    },
    {
        key: "file_client",
        label: "pages.payments.export.table_labels.client",
        thClass: "text-center",
        tdClass: "",
    },
    {
        key: "dt_invoice",
        label: "pages.payments.export.table_labels.dt_invoice",
        thClass: "text-center",
        tdClass: "text-center",
    },
    {
        key: "file_name",
        label: "pages.payments.export.table_labels.filename",
        thClass: "text-center",
        tdClass: "text-center",
    },
    {
        key: "file_log",
        label: "pages.payments.export.table_labels.log",
        thClass: "text-center",
        tdClass: "text-center",
    },
    {
        key: "file_status",
        label: "pages.payments.export.table_labels.status",
        thClass: "text-center",
        tdClass: "text-center",
    },
    {
        key: "actions",
        label: "pages.payments.export.table_labels.actions",
        thClass: "text-center",
        tdClass: "text-center",
    },
];

export const newOrderTableColumns = [
    {
        key: "selection",
        thClass: "new-order-table-cell-selection",
        tdClass: "new-order-table-cell-selection",
    },
    {
        key: "index",
        label: "#",
        thClass: "new-order-table-cell-index",
        tdClass: "new-order-table-cell-index",
    },
    {
        key: "cod_product",
        label: "Cod Produto",
        sortable: true,
        thClass: "new-order-table-cell-cod-product",
        tdClass: "new-order-table-cell-cod-product",
    },
    {
        key: "product",
        label: "Produto",
        sortable: true,
        thClass: "new-order-table-cell-ds-product",
        tdClass: "new-order-table-cell-ds-product",
    },
    {
        key: "qtd",
        label: "Qtd",
        thClass: "new-order-table-cell-qtd",
        tdClass: "new-order-table-cell-qtd",
    },
    {
        key: "selling_price",
        label: "Venda (R$)",
        sortable: true,
        thClass: "new-order-table-cell-price",
        tdClass: "new-order-table-cell-price",
    },
    {
        key: "negotiate_price",
        label: "Negociado (R$)",
        sortable: true,
        thClass: "new-order-table-cell-price",
        tdClass: "new-order-table-cell-price",
    },
    {
        key: "sub_total",
        label: "Sub total (R$)",
        sortable: true,
        thClass: "new-order-table-cell-price",
        tdClass: "new-order-table-cell-price",
    },
    {
        key: "alcada",
        label: "Alçada/RC",
        thClass: "new-order-table-cell-price",
        tdClass: "new-order-table-cell-price",
    },
    {
        key: "actions",
        label: "Ações",
        thClass: "new-order-table-cell-actions",
        tdClass: "new-order-table-cell-actions",
    },
];

export const logsTableColumns = [
    {
        key: "time",
        label: "pages.logs.table_labels.time",
        sortable: false,
        formatter: (value) => {
            return value.toLocaleString("pt-BR", { timeZone: "GMT" });
        },
    },
    {
        key: "nome_empresa",
        label: "pages.logs.table_labels.company_name",
        sortable: false,
    },
    {
        key: "username",
        label: "pages.logs.table_labels.username",
        sortable: false,
    },
    {
        key: "event",
        label: "pages.logs.table_labels.event",
        sortable: false,
    },
];

export const Integration_Logs = [
    {
        key: "empresa_id",
        label: "Empresa_ID",
        sortable: false
    },
    {
        key: "user_id",
        label: "User ID",
        sortable: false
    },
    {
        key: "request_id",
        label: "Request_ID",
        sortable: false
    },
    {
        key: "order_id",
        label: "Order_ID",
        sortable: false
    },
    {
        key: "uuid",
        label: "UUID",
        sortable: false
    },
    {
        key: "status",
        label: "Status",
        sortable: false
    },
    {
        key: "last_update",
        label: "Last_update",
        sortable: false
    },
    {
        key: "integration_key",
        label: "Integration_Key",
        sortable: false
    },
    {
        key: "payload_type",
        label: "Payload Type",
        sortable: false
    },
    {
        key: "dt_timestamp_id",
        label: "Dt_TimeStamp_ID",
        sortable: false
    },
    {
        key: "status_code",
        label: "Status_Code",
        sortable: false
    },

     ////card

    // {
    //     key: "payload",
    //     label: "Payload",
    //     sortable: false
    // },
    // {
    //     key: "response",
    //     label: "Response",
    //     sortable: false
    // },
]

export const helpTableColumns = [
    {
        key: "id",
        thClass: "text-center",
        tdClass: "text-center",
    },

    {
        key: "title",
        label: "Título",
    },
    {
        key: "subtitle",
        label: "Subtítulo",
    },
    {
        key: "created",
        label: "Criado em",
    },
    {
        key: "language",
        label: "Idioma",
        thClass: "text-center",
        tdClass: "text-center",
    },
    {
        key: "clicked",
        label: "Vezes Clicado",
        thClass: "text-center",
        tdClass: "text-center",
    },
    {
        key: "active",
        label: "Ativo",
        thClass: "text-center",
        // tdClass: "text-center",
    },
    {
        key: "type",
        label: "Tipo",
        thClass: "text-center",
        tdClass: "text-center",
    },
    {
        key: "actions",
        label: "",
        thClass: "text-center",
        tdClass: "text-center",
    },
];

export const filesTableColumns = [
    { key: "empresa_id", label: "pages.files.table_labels.empresa_id", sortable: false },
    {
        key: "active_processes",
        label: "pages.files.table_labels.active_processes",
        sortable: false,
    },
    { key: "channel", label: "pages.files.table_labels.channel", sortable: false },
    { key: "timestamp", label: "pages.files.table_labels.dt_timestamp_id", sortable: false },
    { key: "filename", label: "pages.files.table_labels.filename", sortable: false },
    { key: "filename_id", label: "pages.files.table_labels.filename_id", sortable: false },
    { key: "id", label: "pages.files.table_labels.id", sortable: false },
    { key: "input_params", label: "pages.files.table_labels.input_params", sortable: false },
    { key: "last_update", label: "pages.files.table_labels.last_update", sortable: false },
    { key: "lines", label: "pages.files.table_labels.lines", sortable: false },
    { key: "order_id", label: "pages.files.table_labels.order_id", sortable: false },
    { key: "pages", label: "pages.files.table_labels.pages", sortable: false },
    { key: "request_id", label: "pages.files.table_labels.request_id", sortable: false },
    { key: "size", label: "pages.files.table_labels.size", sortable: false },
    { key: "tag", label: "pages.files.table_labels.tag", sortable: false },
    { key: "user_id", label: "pages.files.table_labels.user_id", sortable: false },
];

export const dictionaryTableColumns = [
    {
        key: "empresa_id",
        label: "pages.dictionary.table_labels.empresa_id",
        sortable: false,
        thClass: "text-center",
        tdClass: "text-center",
    },
    {
        key: "de",
        label: "pages.dictionary.table_labels.from",
        sortable: false,
        thClass: "text-center",
        tdClass: "text-center",
    },
    {
        key: "para",
        label: "pages.dictionary.table_labels.to",
        sortable: false,
        thClass: "text-center",
        tdClass: "text-center",
    },
    {
        key: "status",
        label: "pages.dictionary.table_labels.status",
        sortable: false,
        thClass: "text-center",
        tdClass: "text-center",
    },
];

export const curatorTableColumns = [
    {
        key: "selected",
        label: "pages.curator.table_labels.selected",
        tdClass: "cell-bonificacao",
    },
    {
        //0
        key: "_update",
        label: "pages.curator.table_labels._update",
        sortable: false,
        formatter: (value) => {
            return new Date(value).toLocaleString("pt-BR", { timeZone: "GMT" });
        },
    },
    {
        //1
        key: "cnpj",
        label: "pages.curator.table_labels.cnpj",
        sortable: false,
    },
    {
        //2
        key: "nome_cliente",
        label: "pages.curator.table_labels.nome_cliente",
        sortable: false,
    },
    {
        //3
        key: "ds_sku_input",
        label: "pages.curator.table_labels.ds_sku_input",
        sortable: false,
    },
    {
        //5
        key: "new_suggestion",
        label: "pages.curator.table_labels.suggestions",
        thClass: "new-suggestion-column",
        tdClass: "new-suggestion-column",
        sortable: false,
    },
    {
        //6
        key: "vl_p_unitario",
        label: "pages.curator.table_labels.vl_p_unitario",
        tdClass: "text-center",
        sortable: false,
    },
    {
        //7
        key: "qty",
        label: "pages.curator.table_labels.qty",
        tdClass: "text-center",
        sortable: false,
    },
    {
        //8
        key: "dt_entrega",
        label: "pages.curator.table_labels.dt_entrega",
        tdClass: "text-center",
        sortable: false,
    },
    {
        //9
        key: "status_curator",
        label: "pages.curator.table_labels.status_curator",
        thClass: "text-center",
        tdClass: "text-center",
        sortable: false,
    },
];
