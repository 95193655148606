import store from "@/store";

export default (to, from, next) => {
    if (!store.getters.isAuthenticated) next("/login");

    var permissions = store.getters.page_permission[to.name];

    var role = store.getters.role;
    var aux = permissions.indexOf(role);

    const pagesLimited = store.getters.pages_limited || [];

    if (pagesLimited && pagesLimited.length == 0) {
        if (aux >= 0) {
            next();
        } else {
            next("/no_permission");
        }
    } else {
        let indexOf = pagesLimited.indexOf(to.name);
        if (indexOf != -1) next();
        else next();
    }
    next();
};
